import { Link } from "react-router-dom";
import Announcements from "./Announcements";
import Header from "./Header";
// Import images
import img1 from './assets/images/article-images/golden-launch/golden-launch-header.jpg';
import img2 from './assets/images/yego_18.JPG';
import img3 from './assets/images/yego_37.JPG';
import { createClient } from 'contentful';
import { useEffect, useState } from "react";

// Map of images
const imageUrls = {
    img1: img1,
    img2: img2,
    img3: img3,
};

// Initialize Contentful client
const spaceKey = process.env.REACT_APP_SPACE_KEY;
const contentfulKey = process.env.REACT_APP_CONTENTFUL_API_KEY;
const client = createClient({
    space: spaceKey,
    accessToken: contentfulKey
});

// Function to fetch all entries from Contentful
const fetchAllEntries = async () => {
    try {
        const response = await client.getEntries();
        const entries = response.items;

        console.log('Fetched entries:', entries);

        // Optionally process the entries to extract the fields
        const allEntriesFields = entries.map(entry => {
            const fields = {};
            Object.keys(entry.fields).forEach(key => {
                if (entry.fields[key].fields && entry.fields[key].fields.file) {
                    fields[key] = entry.fields[key].fields.file.url;
                } else {
                    fields[key] = entry.fields[key];
                }
            });
            fields["id"] = entry.sys.id;
            return fields;
        });

        return allEntriesFields;
    } catch (error) {
        console.error('Error fetching entries:', error);
        return null;
    }
};

// NewsPageContents component
const NewsPageContents = () => {
    const [entries, setEntries] = useState([]);

    useEffect(() => {
        const fetchEntries = async () => {
            const allEntries = await fetchAllEntries();
            console.log('bbx', allEntries)
            setEntries(allEntries);
        };

        fetchEntries();
    }, []);

    return (
        <>
            <Announcements />
            <Header />
            <section className='background-section-1 padding-2'>
                <h1 className='header-title-3'>News And Events</h1>
            </section>
            {entries.length > 0 ? (
                    entries.map((entry, index) => (
            <section className="content-1 article">
                <div className="article-type">
                    Event
                </div>
                <div className="article-content">
                    <h1>{entry.title}</h1>
                    <div className="lede">{entry.prologue}</div>
                    <div className="full-alticle">
                        {/* <img src={imageUrls.img1} alt="Grand Opening" /> */}
                        {entry.featuredImage && (<img src={entry.featuredImage} alt={entry.title} />)}
                        <Link to="/articlepage" state={{ entryId: entry.id }}>Read More</Link>
                    </div>
                    
                </div>
            </section>

))
) : (
    <p>Loading entries...</p>
)}
            {/* <section className="content-1 article">
                <div className="article-type">
                    Event
                </div>
                <div className="article-content">
                    <h1>Grand Opening, and New Product Launching</h1>
                    <div className="lede">
                        Kigali, Rwanda – July 27, 2024 – Yego Manufacturers Ltd is thrilled to announce a landmark event in their history: the grand opening of the Golden Gorilla Apartment, coupled with the highly anticipated launch of their newest product, "Lion Heart." Set against the backdrop of the luxurious Golden Gorilla Apartment Hotel near Remera Mutsindashyaka House, this event promises to be a celebration of innovation, tradition, and excellence in the alcoholic beverage industry.
                    </div>
                    <div className="full-alticle">
                        <img src={imageUrls.img1} alt="Grand Opening" />
                        <Link to="/articlepage" state={{ entryId: "3g6jNmeRiNZt7IGAkCg8ty" }}>Read More</Link>
                    </div>
                </div>
            </section> */}
        </>
    );
}

export default NewsPageContents;
