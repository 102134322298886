import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import image1 from '../assets/images/inkorora-pack.png'

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList() { 
  return (
    <>
      {/* <section className='background-section-1 padding-2'>
          <h1 className='header-title-3'>News And Events</h1>
      </section> */}
    
    <ImageList
      sx={{ width: '100%', }}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem component="div" className="image-component" key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
            style={{
                objectFit:'cover'
            }}
          />
          <div className='image-description'>
        <div className='title'>
        <h2>{item.shortDescription}</h2>
        <h4>{item.title}</h4>
        </div>
      </div>
        </ImageListItem>
      ))}
      
    </ImageList>
    </>
  );
}

const itemData = [
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723034111/yego_40-min_zhxhnn.jpg',
    title: 'Car Parking',
    shortDescription:'Industry outer view',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723030430/yego_20_tnj4nw.jpg',
    title: 'New Gins',
    shortDescription:'Gorilla Power & Lion Heart',
  },
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723030409/yego_18_upxagm.jpg',
    title: 'Gorilla Power Gin',
    shortDescription:'3 Gorilla Power Gins with labels',
  },
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723030371/yego_15_lfztp7.jpg',
    title: 'Leyon',
    shortDescription:'flavorous gin',
    cols: 2,
  },
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723030328/yego_22_ryoyyd.jpg',
    title: 'New Gins',
    shortDescription:'Leyon, Lion Heart Black, Gorilla Power Gins',
    cols: 2,
  },
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723030266/ndumunyarwanda-pack_gnt2a7.jpg',
    title: 'Ndumunyarwanda Candies',
    shortDescription:'Made in Rwanda candies',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723034221/yego_27-min_u3u1jt.jpg',
    title: 'Industry Outer View',
    shortDescription:'Nice industry outlook',
  },
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723030260/inkorora-pack_esshdv.jpg',
    title: 'Inkorora Candies',
    shortDescription:'Made in Rwanda Candies',
  },
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723030263/grand-ndumunyarwanda_exheli.jpg',
    title: 'Big Ndumunyarwanda',
    shortDescription:'Made in Rwanda',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723035685/yego_1-min_gjxvzw.jpg',
    title: 'Leyon in production 1',
    shortDescription:'',
  },
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723035692/yego_4-min_dpc2te.jpg',
    title: 'Leyon in production 2',
    shortDescription:'',
  },
  {
    img: 'https://res.cloudinary.com/dbgz4wx8k/image/upload/v1723035685/yego_1-min_gjxvzw.jpg',
    title: 'Leyon in production 3',
    shortDescription:'',
    cols: 2,
  },
];
