import Carousel from 'react-bootstrap/Carousel';
import yego_0 from './assets/images/yego_0.JPG'; 
import yego_1 from './assets/images/yego_5.JPG'; 
import yego_2 from './assets/images/yego_15.JPG'; 
import yego_3 from './assets/images/WhatsApp Image 2024-12-03 at 03.10.10_392fa9e4.jpg'; 
// import yego_3 from './assets/images/yego_3.JPG'; 
import { motion } from 'framer-motion';

function UncontrolledExample() {
  return (
    <Carousel >
      <Carousel.Item className=''>
        <motion.img
        from={{
          opacity:0,
        }}
        animate={{
          opacity:1,
        }}
        // style={{
        //   objectFit:'contain'
        // }}
        className='header-image' src={yego_3}
        />
        <Carousel.Caption >
          <div className='onMobileCenter'>
          <h3 className=''>Welcome to Yego Manufacturers</h3>
          <p className='onMobile'>At Yego Manufacturers, we take pride in being the leading producer of beverages, candies, and other delightful treats in Rwanda. Our commitment to quality and innovation ensures that every product we create brings joy and satisfaction to our customers.</p>
          </div>
          </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <motion.img className='header-image' src={yego_1}/>
        <Carousel.Caption>
        <div className='onMobileCenter'>
          <h3>Discover Quality and Quantity at Yego Manufacturers</h3>
          <p className='onMobile'>At Yego Manufacturers, we are dedicated to producing the finest beverages, candies, and treats right here in Kigali, Rwanda. Our commitment to quality and innovation ensures that every product we create meets the highest standards of excellence.</p>
        </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img className='header-image' src={yego_2}/>
        <Carousel.Caption>
        <div className='onMobileCenter'>
          <h3>Introducing Leyon Vodka: The New Standard in Quality</h3>
          <p  className='onMobile'>
          Welcome to Yego Manufacturers, where innovation meets tradition in the heart of Kigali, Rwanda. We are excited to introduce our newest product, Leyon Vodka, crafted with precision and care to deliver an exceptional drinking experience.
          </p>  
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;