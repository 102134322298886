import { Box, Button, Paper } from "@mui/material";
import beer_category_image from "./assets/product-categories-images/beer-category.jpg"
import juice_category_image from "./assets/product-categories-images/juices-category.jpg"
import candy_category_image from "./assets/product-categories-images/candies-category.jpg"
import { createContext } from "react";



const SelectProductCategory = ({selectedProductProp,setSelectedProductProp}) => {
    return ( <Paper className="select-product-category">
       <Box className = 'select-product-buttons' sx={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr', }}>
         <div
         style={{
            height: '100%',
            width: '100%',
         }}
         onClick={()=> setSelectedProductProp("Liquor")}
         >
            <img src={beer_category_image}/>
            <div className="category-title">Beer & Likers</div>
            </div>
         <div
         onClick={()=> setSelectedProductProp("Juice")}
         >
         <img src={juice_category_image}/>
         <div className="category-title">Juices & Beverages</div>
         </div>
         <div
            onClick={()=> setSelectedProductProp("Candy")}
         >
         <img src={candy_category_image}/>
         <div className="category-title">Candies</div>
         </div>
       </Box>
    </Paper> );
}
 
export default SelectProductCategory;