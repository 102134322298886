import React, { useEffect, useState } from 'react';
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import articleImage from './assets/images/article-images/golden-launch/golden-launch-header.jpg';
import DateUsLocale from './components/Date';


const spaceKey = process.env.REACT_APP_SPACE_KEY;
const contentfulKey = process.env.REACT_APP_CONTENTFUL_API_KEY;
const client = createClient({
    space: spaceKey,
    accessToken: contentfulKey
});

const fetchEntry = async (entryId) => {
    try {
        const entry = await client.getEntry(entryId);
        console.log('pupu da',entry.fields)

        return {
            featuredImage: entry.fields.featuredImage.fields.file.url,
            alticle: entry.fields.article,
            prologue: entry.fields.prologue,
            postDate: entry.fields.date,
            title: entry.fields.title
        }; 
    } catch (error) {
        console.error('Error fetching entry:', error);
        return null;
    }
};

const ArticlePageContent = ({entryId}) => {
    const [richText, setRichText] = useState(null);
    const [prologue, setPrologue] = useState(null);
    const [postDate, setPostDate] = useState(null);
    const [title, setTitle] = useState(null);
    const [articleImage, setArticleImage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchEntry(entryId); // Replace with your Entry ID
            // const data = await fetchEntry('5GxkOqXvAg0dE1tYvXcoNL'); // Replace with your Entry ID
            // const data = await fetchEntry('3g6jNmeRiNZt7IGAkCg8ty'); // Replace with your Entry ID
            // console.log('pupu da',data);
            setRichText(data.alticle);
            setPrologue(data.prologue);
            setPostDate(data.postDate);
            setTitle(data.title);
            setArticleImage(data.featuredImage);
        };

        fetchData();
    }, []);


    return (
        <div className="article-page">

            <div className="article-header">
                <div className="article-image">
                    {/* <img src={articleImage} alt="Golden Launch" /> */}
                    <img src={articleImage ? articleImage : 'Loading...'} alt="Golden Launch" />
                </div>
                <div>
                    <span>{title ? title : 'Loading...'}</span>
                    {/* <span>Grand Opening, and New Product Launching</span> */}
                    <span>{prologue ? prologue : 'Loading...'}</span>
                    {/* <span>Kigali, Rwanda – July 27, 2024 – Yego Manufacturers Ltd is thrilled to announce a landmark event in their history: the grand opening of the Golden Gorilla Apartment, coupled with the highly anticipated launch of their newest product, "Lion Heart." Set against the backdrop of the luxurious Golden Gorilla Apartment Hotel near Remera Mutsindashyaka House, this event promises to be a celebration of innovation, tradition, and excellence in the alcoholic beverage industry.</span> */}
                    <span>Posted on:  {postDate ? <DateUsLocale date={postDate}/> : 'Loading...'}</span>
                    {/* <span>Posted on:  24 July, 2024</span> */}
                </div>
            </div>
            <section className='content-1 article-main'>
                {/* Render the Rich Text content here */}
                {richText ? documentToReactComponents(richText) : 'Loading...'}
            </section>
        </div>
    );
};

export default ArticlePageContent;
