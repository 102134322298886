import { Paper } from "@mui/material";
import beer_1 from "./assets/products-images/download.jpg";
import { motion } from "framer-motion";
import NewProducts from "./NewProducts";
import SelectProductCategory from "./SelectProductCategory";
import Announcements from "./Announcements";
import Header from "./Header";
import Footer from "./footer";

import gorillaPowerGin from './assets/products-images/gorilla power gin.png';
import gorillaPowerCoconut from './assets/products-images/gorilla coconut.png';
import leyonVodka from './assets/products-images/leyon_vodka.png';
import lionHeartSpecialRum from './assets/products-images/lion heart special rum.png';
import lionHeartWhiteRum from './assets/products-images/lion heart white rum.png';

import ndumunyarwandaBig from './assets/products-images/Ndumunyarwanda-big.jpg';
import ndumunyarwandaSmall from './assets/products-images/Ndumunyarwanda-small.jpg';
import bombo from './assets/products-images/Bombo.jpg';
import inkorora from './assets/products-images/Inkorora.jpg';

import appleFreshJuiceBig from './assets/products-images/apple flesh juice big.png';
import appleFreshJuiceSmall from './assets/products-images/apple flesh juice small.png';
import mangoFreshJuice from './assets/products-images/mango flesh juice yego copy.png';
import { useState } from "react";




const products = [
    {
    productCategory:"Liquor",
    productName:"Gorilla Power Gin",
    productImage:gorillaPowerGin,

    },
    {
    productCategory:"Liquor",
    productName:"Gorilla Coconut",
    productImage:gorillaPowerCoconut,
    },
    {
    productCategory:"Liquor",
    productName:"Leyon Vodka",
    productImage:leyonVodka,
    },
    {
    productCategory:"Liquor",
    productName:"Lion Heart (Special ram)",
    productImage:lionHeartSpecialRum,
    },
    {
    productCategory:"Liquor",
    productName:"Lion Heart (white ram)",
    productImage:lionHeartWhiteRum,
    },
    {
    productCategory:"Candy",
    productName:"Ndumunyarwanda (small)",
    productImage:ndumunyarwandaSmall,
    },
    {
    productCategory:"Candy",
    productName:"Ndumunyarwanda (big)",
    productImage:ndumunyarwandaBig,
    },
    {
    productCategory:"Candy",
    productName:"Inkorora",
    productImage:inkorora,
    },
    {
    productCategory:"Juice",
    productName:"Apple Fresh Juice big",
    productImage:appleFreshJuiceBig,
    },
    {
    productCategory:"Juice",
    productName:"Apple Fresh Juice Small",
    productImage:appleFreshJuiceSmall,
    },
    {
    productCategory:"Juice",
    productName:"Mango Fresh Juice",
    productImage:mangoFreshJuice,
    },

]
 
const Products = () => {
    const [selectedProduct, setSelectedProduct] = useState("Liquor");

    
    return (
        <>
         <Announcements/>
         <Header/>
        <NewProducts/>
        <SelectProductCategory selectedProductProp = {selectedProduct} setSelectedProductProp = {setSelectedProduct} />
            <motion.div className="products" 
            variants={{
                hidden: { opacity: 0 },
                show: {
                    opacity: 1,
                    transition: {
                        staggerChildren: 0.45,
                    },
                },
            }}
            initial="hidden"
            animate="show"
        >
            {products.map((product, index) => (
                (selectedProduct === product.productCategory &&  <motion.div variants={{hidden:{
                    opacity:0,
                    x:-200,
                }, show:{
                    x:0,
                    opacity:1,
                    }}}>
                    <Paper key={index} className="product-card">
                        {/* <div className="product-top">5000frw - 8000frw</div> */}
                        <img className="product-image-card" src={product.productImage} alt={`Product ${index + 1}`} />
                        <div className="product-info">
                            <div className="product-title">{product.productName}</div>
                            <div className="product-description">Available for suppriers & retailers</div>
                        </div>
                    </Paper>
                    </motion.div>)
               
            )
            )}
        </motion.div>
        <Footer/>
        </>
        
    );
}

export default Products;
