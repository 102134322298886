import './App.css';
import Header from './Header';
import Announcements from './Announcements';
import BasicExample from './form';

// products-images
const ContactPageContents = () => {
    return ( 
       <>
        <Announcements/>
        <Header/>
        <section className='background-section-1 padding-2'>
          <h1 className='header-title-3'>Contact Us</h1>
        </section>

        <section  className='content-1 flex-1 contact'>
        <div className='padding-1 contact-us' >
            <div className=''>
           <h5> OUR HEAD OFFICE ADDRESS</h5>
           <p style={{color:'#8a8a8a'}}>We are located in Kigali City.</p>
            <hr/>
           <p style={{color:'#8a8a8a'}}>Yego Manufacturers, KK 15 Rd, GAHANGA,</p>
           <p style={{color:'#8a8a8a'}}>Kicukiro, Kigali</p>
           <p style={{color:'#8a8a8a'}}>Rwanda</p>
           <hr/>
           <p style={{color:'#8a8a8a'}}><b>Phone</b>: +250 787 750 621 (Customer care)</p>
           <p style={{color:'#8a8a8a'}}><b>Phone</b>: +250 788 307 710 (Office 1)</p>
           <p style={{color:'#8a8a8a'}}><b>Phone</b>: +250 788 526 913 (Office 2)</p>
           <p style={{color:'#8a8a8a'}}><b>Email</b>: yegomanufactures@gmail.com</p>

            </div>
        </div>
        <div className='contact-us contact-us-1'>
        <BasicExample/>
        </div>
      
    </section>

  

       </>
     );
}


export default ContactPageContents;