import React from 'react';

const DateUsLocale = ({ date }) => {
    // Convert the date string to a Date object if it's a string
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    
    return (
        <>
            {formatDate(dateObj)}
        </>
    );
}

function formatDate(date) {
    
    const options = {
        weekday: 'long',  // e.g., "Monday"
        year: 'numeric',  // e.g., "2024"
        month: 'long',    // e.g., "September"
        day: 'numeric',   // e.g., "24"
        hour: '2-digit',  // e.g., "12 AM"
        minute: '2-digit',// e.g., "00"
        timeZoneName: 'short' // e.g., "CEST"
    };
    return date.toLocaleString('en-US', options);
}

export default DateUsLocale;
