import './App.css';
import Header from './Header';
import Announcements from './Announcements';
import yego_6 from './assets/images/yego_6.JPG';
import bottle from './assets/others/icons/bottle-alcohol-wine-svgrepo-com.png';
import juiceCan from './assets/others/icons/juice-svgrepo-com.png';
import Candies from './assets/others/icons/three-halloween-candies-svgrepo-com.png';
import team1 from './assets/images/team-1.jpg';
import team2 from './assets/images/team-2.jpg';
import team3 from './assets/images/team-3.jpg';
import visionSvg from './assets/images/other-assets/vission.svg'
import mission from './assets/images/other-assets/mission.svg'

// products-images
const AboutPageContents = () => {
    return ( 
       <>
        <Announcements/>
        <Header/>
        <section className='background-section-1 padding-2'>
          <h1 className='header-title-3'>About us</h1>
        </section>

        <section  className='content-1 about-1'>
        <div className='about-image-container-1'>
        <img className='large-image'  src={yego_6}/>
        </div>
        <div className=''>
        <div className=''>
        <div><span className='header-title-2'>MISSION</span></div>
        <br/>
        <div className='phone-size-1'><img className='hidden-2' style={{float:'left',marginTop:'10px', marginRight:'10px'}} src={mission} alt='missionSVG'/>
        Our mission is to craft premium, high-quality spirits & confectionaries that enhance life's celebratory moments. We are dedicated to sustainable practices, innovation, and maintaining the highest standards of excellence, ensuring every bottle reflects our passion for perfection and our commitment to our customers, employees, and communities.
        </div>
        </div>
        <br/>
        <br/>
        <div className=''>
        <div><span className='header-title-2'>VISION</span></div>
        <br/>
        <div><img className='hidden-2' style={{float:'left',marginTop:'10px', marginRight:'10px'}} src={visionSvg} alt='missionSVG'/>
        Our vision is to be the world's most respected and sustainable liquor brand, known for our innovation, quality, and positive impact on society. We aspire to create unforgettable experiences for our customers, foster a culture of creativity and integrity within our team, and lead the industry in environmental stewardship.</div>
        </div>
        </div>
    </section>

    {/* <section className="content-1">
        <div className="centered-div">
            <div>Management Team</div>
        </div>
    </section>

    <section className="content-1 cards flex-1">
        <div className="card-2">
            <div className="headshot-holder image-holder">
                <img className=''  src={team1}/>
            </div>
            <div className="card-info-2">
                <div className='emproyee-name'>DUSHIME Diedone</div>
                <div className='emproyee-post'>MANAGER</div>
            <p><span> </span></p>
            <p><span> </span></p>
            </div>
        </div>
        <div className="card-2">
        <div className="headshot-holder image-holder">
        <img src={team2}/>
            </div>
            <div className="card-info-2">
                <div className='emproyee-name'>DUSHIME Diedone</div>
                <div className='emproyee-post'>MANAGER</div>
            <p><span> </span></p>
            <p><span> </span></p>
            </div>
        </div>
        <div className="card-2">
        <div className="headshot-holder image-holder">
        <img src={team3}/>
            </div>
            <div className="card-info-2">
                <div className='emproyee-name'>DUSHIME Diedone</div>
                <div className='emproyee-post'>MANAGER</div>
            <p><span> </span></p>
            <p><span> </span></p>
            </div>
        </div>
    </section> */}
    <section className="content-1">
    <div><span className='header-title-2'>Our history</span></div>
    <p>
    Yego Manufacturers Industry began its journey by producing high-quality juices, cosmetics, and confectioneries. Building on this foundation, in 2019, we expanded our expertise into the world of alcoholic beverages. Since then, we have been dedicated to crafting exceptional liquors that celebrate the rich flavors and traditions of Rwanda. Our commitment to quality and innovation has made Yego a trusted name in both the non-alcoholic and alcoholic beverage industries.
    </p>
    </section>
    <section className="content-1"><div><span className='header-title-2'>Why choosing YEGO's products?</span></div>
    <p>
    At YEGO!, our values and philosophy are what set us apart. We are dedicated to crafting exceptional spirits through a harmonious blend of tradition and innovation. Our commitment to quality, sustainability, and ethical practices guides every decision we make. We believe in creating unforgettable experiences for our customers and supporting the communities we are a part of. With a holistic approach to production and a passion for excellence, we aim to enrich the cultural tapestry of the liquor industry while leading the way in environmental stewardship and social responsibility.
    </p>
    </section>
    <section className="content-1">
        <div className="centered-div">
            <div>Location & Maps</div>
        </div>
    </section>
    <section className="content-1 location-section">
        <table
         style={{
            margin:'auto',
            width:'40%'
         }}
         className='location-table'
        >
            <tr>
                <td><b>Country</b></td> <td>Rwanda</td>
            </tr>
            <tr>
                <td><b>Province</b></td> <td>Kigali City</td>
            </tr>
            <tr>
                <td><b>District</b></td> <td>Kicukiro </td>
            </tr>
            <tr>
                <td><b>Sector</b></td> <td>gahanga</td>
            </tr>
            <tr>
                <td><b>Cell</b></td> <td>Nunga</td>
            </tr>
            <tr>
                <td><b>Village</b></td> <td>Kigarama </td>
            </tr>
            <tr>
                <td><b>Google Map Address</b></td> <td>X35W+H4W, NR5, Kigali</td>
            </tr>
        </table>
    </section>
    <section className="content-1">
    <div style={{maxWidth:'100%',margin:'auto',listStyle:'none', transition: 'none',overflow:'hidden',width:'500px',height:'500px',}}><div id="embed-ded-map-canvas" style={{height:'100%', width:'100%',maxWidth:'100%',}}><iframe style={{height:'100%',width:'100%',border:0}} frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=yego+manufacturers&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="embed-ded-maphtml" href="https://www.bootstrapskins.com/themes" id="grab-map-data">premium bootstrap themes</a></div>
    </section>
       </>
     );
}


export default AboutPageContents;