import Announcements from "./Announcements";
import QuiltedImageList from "./components/Gallery";
import Footer from "./footer";
import Header from "./Header";
import HomePageContents from "./HomePageContents";

const Gallery = () => {
    return ( <>
          <Announcements/>
        <Header/>
        <QuiltedImageList/>
        <Footer/>
    </> );
}
 
export default Gallery;