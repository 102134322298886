import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser'

function BasicExample() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_smg0a8w', 'template_ogehpf3', form.current, {
        publicKey: 'aflRthubVhdkINwbW',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <>
    <form ref={form} onSubmit={sendEmail} className='contact-form'>
        <input name='from_name' placeholder='YOUR NAME...'/>
        <input name='User_email' placeholder='EMAIL ADRESS...'/>
        <input name='phone_number' placeholder='PHONE NUMBER...'/>
        <textarea name='message' placeholder='MESSAGE...' rows="4" />
        <input type='submit' value="SEND"/>
    </form>
    </>
  );
}

export default BasicExample;