// src/components/CountUpComponent.js

import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const CountUpComponent = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className='hidden-2' ref={ref}>
      {inView && (
        <div className='countup-div'>
        <div>
            <span>Cells</span>
            <span><CountUp start={1000} end={5000} duration={5} /></span>
        
        </div>
        <div>
       <span>Outreachs</span>
            <span><CountUp start={55} end={100} duration={5} />+</span>
        </div>
        <div>
       <span>Production</span>
            <span><CountUp start={500} end={2000} duration={5} />+</span>
        </div>
        <div>
       <span>Upvotes</span>
            <span><CountUp start={5} end={100} duration={5} />+</span>
        </div>
        </div>
      )}
    </div>
  );
};

export default CountUpComponent;
