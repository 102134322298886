import './App.css';
import Header from './Header';
import Announcements from './Announcements';
import AboutPageContents from './AboutPageContents';
import Footer from './footer';


const About = () => {
    return ( 
       <>
        <AboutPageContents/>
        <Footer/>
       </>
     );
}
 
export default About;