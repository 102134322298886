import { Paper } from "@mui/material";
import flyer from "./assets/images/flyer1.jpg";
import lion_black from "./assets/products-images/yego new product_lion_black_.jpg";
import lion_white from "./assets/products-images/yego new product_lion_white_.jpg";

const displaySyles ={
    position: 'absolute',
    backgroundColor: '#0F731C',
    height: '90%',
    width: '98%',
    left:'50%',
    transform:'translateX(-50%)',
    top:'10px',
    borderRadius:'5px',
    boxShadow:'0 0 4px black',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
}


const NewProducts = () => {
    return (<div className="new-products flex-3">
                <Paper className="flyer special-0 special-1">
          <img src={flyer}/>
        </Paper>
        <Paper className="liquers special-0 hidden-2">
            <div className="liquer"><img  src={lion_black}/></div>
            <div className="liquer"><img  src={lion_white}/></div>
            <div className="animated-1" style={{width:'100%',height:'36vh'}}>
            <div className="display" style={displaySyles}>
                <span>Yego!!!</span>
                <span>Nibyo, twazanye ibinyobwa bishya...</span>
            </div>
            </div>
        </Paper>                      
      </div> );
}
 
export default NewProducts;