import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
const Footer = () => {
    return ( <>
      <section className='footer footer-1'>
        <div style={{display:'flex',flexDirection:'column'}}>
            <span className='logo-3'>Yego</span>
            <span className='logo-4'>Manufacturers</span>
        </div>
        <div className='links'>
            <span>Contact Info</span>
            <span>Google Maps : X35W+H4W, NR5, Kigali</span>
            <span>Kigali City, Kicukiro,Gahanga,</span>
            <span>Phone : +250 787 750 621</span>
            <span>Email : yegomanufactures@gmail.com</span>
            </div>
            <div className='links'>
            <span>Important Link</span>
            <span><a href="/contact">Contact us</a></span>
            <span><a href="/about">About us</a></span>
            <span><a href="/products">Our products</a></span>
            <span><a href="/news">News</a></span>
            </div>
            <div className='links'>
            <span>Events</span>
            <span>Launching new rums! (pending) </span>
            </div>
    </section>
    <section className='footer footer-2'>
        <div>
            <span>Copyright ©2024 All rights reserved | Yego Manufacturers Ltd</span>
        </div>
        <div className="social-media-links">
            {/* <span><a href=""><FontAwesomeIcon icon={faFacebookF} /></a></span>
            <span><a href=""><FontAwesomeIcon icon={faTwitter} /></a></span> */}
            <span><a href="https://www.instagram.com/yegomanufacturers/"><FontAwesomeIcon icon={faInstagram} /></a></span>
            <span><a href="https://rw.linkedin.com/company/yego-manufacturers"><FontAwesomeIcon icon={faLinkedin} /></a></span>
        </div>
    </section>
    </> );
}
 
export default Footer;