import './App.css';
import Header from './Header';
import Announcements from './Announcements';
import ContactPageContents from './ContactPageContents';
import Footer from './footer';



const Contact = () => {
    return ( 
       <>
        <ContactPageContents/>
        <Footer/>
       </>
     );
}
 
export default Contact;