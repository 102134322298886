import './App.css';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import Carousel from 'react-bootstrap/Carousel';
import { motion } from 'framer-motion';

function Announcements() {
  return (
    <Carousel controls={false} indicators={false}>
      <Carousel.Item>
             <motion.div className='announcements'>
           <div>Contact us now!</div>
           <div><LocalPhoneIcon/> +250 787 750 621 </div>
            <div><EmailIcon/> yegomanufactures@gmail.com</div>
        </motion.div>
      </Carousel.Item>
      <Carousel.Item>
             <motion.div className='announcements' style={{background: "white", color:"green"}}>
           <div style={{fontWeight:"bold"}}>Drive sober, stay safe. Don’t drink and drive.</div>
        </motion.div>
      </Carousel.Item>
      <Carousel.Item>
             <motion.div className='announcements'>
           <div>Contact us now!</div>
           <div><LocalPhoneIcon/> +250 787 750 621 </div>
            <div><EmailIcon/> yegomanufactures@gmail.com</div>
        </motion.div>
      </Carousel.Item>
      <Carousel.Item>
             <motion.div className='announcements' style={{background: "white", color:"green"}}>
           <div style={{fontWeight:"bold"}}>Drink responsibly. Be at least 18 to enjoy alcohol legally.</div>
        </motion.div>
      </Carousel.Item>
    </Carousel>
  );
}
 
export default Announcements;