import './App.css';
import Header from './Header';
import Announcements from './Announcements';
import ContactPageContents from './ContactPageContents';
import Footer from './footer';
import NewsPageContents from './NewsPageContents';



const News = () => {
    return ( 
       <>
        <NewsPageContents/>
        <Footer/>
       </>
     );
}
 
export default News;