import logo from './assets/logo.png'
import './App.css';
import UncontrolledExample from './courousel';
import { Box, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HomeIcon from '@mui/icons-material/Home';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import SelectProductCategory from './SelectProductCategory';
import Products from './Products';
import NewProducts from './NewProducts';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import News from './News';
import Articlepage from './ArticlePage';
import Gallery from './Gallery';



const App = () => {
    return ( <div className="App">
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/news" element={<News />} />
        <Route path="/articlepage" element={<Articlepage />} />
        <Route path="/gallery" element={<Gallery />} />
        {/* <Route path="/golden-launching" element={<ArticlePage />} /> */}
        </Routes>
    </div> );
}


 
export default App;